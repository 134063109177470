<template>
    <aside class="side-panel d-block card bg-white shadow-sm w-100 h-100">
        <slot></slot>
    </aside>
</template>

<script>
export default {
    name: "SidePanel",
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.side-panel {
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background-color: rgba(255, 255, 255, 1);

        z-index: 10;
    }

    &.slide-in-enter-active,
    &.slide-in-leave-active {
        transition: transform 250ms ease;
    }

    &.slide-in-enter,
    &.slide-in-leave-to {
        transform: translate3d(105%, 0, 0);
    }

    &.slide-in-enter-to,
    &.slide-in-leave {
        transform: translate3d(0, 0, 0);
    }
}
</style>
