<template>
    <side-panel class="client-details d-flex flex-column">
        <div class="position-sticky title d-flex align-items-center pl-2 pr-3 pt-3 pb-2">
            <div>
                <router-link class="btn btn-outline-dark border-0 mr-1"
                             to="/clients">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <div class="flex-grow-1">
                <h3 class="font-weight-bolder text-uppercase text-right mb-1">
                    Details
                </h3>
            </div>
        </div>

        <div class="p-2 flex-grow-1">
            <h2 class="font-weight-bolder card-title mb-0">
                {{ client.name }}
            </h2>
            <p>
                {{ client.email }}
            </p>

            <div class="mb-3">
                <label class="text-secondary mb-0">
                    🏷️ Category
                </label>
                <h5 class="font-weight-bold mb-0">
                    {{ client.category.name }}
                </h5>
            </div>

            <div class="mb-2">
                <label class="text-secondary mb-1">
                    🏠 {{ areAddressesTheSame ? "" : "Postal" }} Address
                </label>
                <p class="text-center mb-0"
                   v-if="!postalAddress">
                    --- No {{ areAddressesTheSame ? "" : "postal" }} address ---
                </p>
                <address v-else>
                    <strong>{{ postalAddress.attention }}</strong><br>
                    {{ postalAddress.address }}<br>
                    {{ postalAddress.city }}<br>
                    {{ postalAddress.region }}, {{ postalAddress.postalCode }}<br>
                    {{ postalAddress.country }}
                </address>
            </div>
            <div class="mb-2"
                 v-if="!areAddressesTheSame">
                <label class="text-secondary mb-1">
                    🏠 {{ areAddressesTheSame ? "" : "Street" }} Address
                </label>
                <p class="text-center mb-0"
                   v-if="!streetAddress">
                    --- No street address ---
                </p>
                <address v-else>
                    <strong>{{ streetAddress.attention }}</strong><br>
                    {{ streetAddress.address }}<br>
                    {{ streetAddress.city }}<br>
                    {{ streetAddress.region }}, {{ streetAddress.postalCode }}<br>
                    {{ streetAddress.country }}
                </address>
            </div>

            <div class="mb-3">
                <label class="text-secondary mb-0">
                    ☎️ Phone
                </label>
                <p class="text-center mb-0"
                   v-if="!phone">
                    --- No phone ---
                </p>
                <p class="font-weight-bold mb-0"
                   v-else>
                    {{ phone }}
                </p>
            </div>

            <div class="mb-3"
                 v-if="fax">
                <label class="text-secondary mb-0">
                    📠 Fax
                </label>
                <p class="font-weight-bold mb-0">
                    {{ fax }}
                </p>
            </div>

            <div class="mb-3"
                 v-if="mobile">
                <label class="text-secondary mb-0">
                    📱 Mobile
                </label>
                <p class="font-weight-bold mb-0">
                    {{ mobile }}
                </p>
            </div>

            <div class="mb-4">
                <label class="text-secondary mb-1">
                    💰 Point Scheme
                </label>
                <p class="text-center mb-0"
                   v-if="!pointScheme">
                    --- No point scheme joined ---
                </p>
                <div class="d-flex justify-content-between flex-nowrap"
                     v-else>
                    <h5 class="font-weight-bolder mb-0">
                        {{ pointScheme.name }}
                    </h5>
                    <h5 class="font-weight-normal mb-0">
                        <small>Balance:</small> <span class="font-weight-bolder">{{ pointBalance }}</span>
                    </h5>
                </div>
            </div>

            <div class="mb-4">
                <label class="text-secondary mb-1">
                    🏵️ Badges
                </label>
                <p class="text-center mb-0"
                   v-if="badges.length === 0">
                    --- No badge yet ---
                </p>
                <h5 class="font-weight-normal mb-0"
                    v-else>
                    <span class="badge badge-primary mr-1"
                          v-for="{ id, name } in badges"
                          v-bind:key="id">
                        {{ name }}
                    </span>
                </h5>
            </div>
        </div>

        <div class="actions position-sticky p-2">

            <div class="d-flex justify-content-between mb-2">
                <button class="btn btn-block btn-primary font-weight-bolder shadow-sm m-0 mr-1"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="isModalAdjustPointsOpen = true">
                    💰 Adjust points
                </button>
                <button class="btn btn-block btn-info font-weight-bolder shadow-sm m-0 ml-1"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="isModalSendEmailOpen = true">
                    📧 Send email
                </button>
            </div>
            <div class="d-flex justify-content-between">
                <button class="btn btn-block btn-warning font-weight-bolder shadow-sm m-0 mr-1"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="isModalClientOpen = true">
                    ✍️ Update
                </button>
                <button class="btn btn-block btn-danger font-weight-bolder shadow-sm m-0 ml-1"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="deleteClient">
                    🗑️ Delete
                </button>
            </div>
        </div>

        <modal-client is-editing
                      v-bind:client="client"
                      v-model="isModalClientOpen">
        </modal-client>
        <modal-adjust-points v-bind:client="client"
                             v-model="isModalAdjustPointsOpen">
        </modal-adjust-points>
        <modal-send-email v-bind:client="client"
                          v-model="isModalSendEmailOpen">
        </modal-send-email>
    </side-panel>
</template>

<script>
import SidePanel from "../SidePanel";
import ModalClient from "./ModalClient";
import ModalSendEmail from "./ModalSendEmail";
import ModalAdjustPoints from "./ModalAdjustPoints";
export default {
    name: "ClientDetails",
    components: {
        ModalAdjustPoints,
        ModalSendEmail,
        ModalClient,
        SidePanel,
    },
    props: {
        clientId: {
            type: String,
        },
    },
    data () {
        return {
            isLoading: false,
            isModalClientOpen: false,
            isModalAdjustPointsOpen: false,
            isModalSendEmailOpen: false,
        };
    },
    computed: {
        client () {
            return this.$store.getters["data/getClientById"](this.clientId);
        },
        postalAddress () {
            return this.client?.postal?.attention &&
            this.client?.postal?.address &&
            this.client?.postal?.city &&
            this.client?.postal?.region &&
            this.client?.postal?.country ?
                this.client?.postal :
                null;
        },
        streetAddress () {
            return this.client?.street?.attention &&
            this.client?.street?.address &&
            this.client?.street?.city &&
            this.client?.street?.region &&
            this.client?.street?.country ?
                this.client?.street :
                null;
        },
        areAddressesTheSame () {
            const { street, postal } = this.client;
            return street.attention === postal.attention &&
                street.address === postal.address &&
                street.city === postal.city &&
                street.region === postal.region &&
                street.country === postal.country;
        },
        phone () {
            const { phoneCountry, phoneArea, phoneNumber } = this.client;
            return phoneCountry || phoneArea || phoneNumber ?
                `${ phoneCountry ? `+${ phoneCountry }` : "" } ${ phoneArea ?? "" } ${ phoneNumber ?? "" }` :
                ``;
        },
        fax () {
            const { faxCountry, faxArea, faxNumber } = this.client;
            return faxCountry || faxArea || faxNumber ?
                `${ faxCountry ? `+${ faxCountry }` : "" } ${ faxArea ?? "" } ${ faxNumber ?? "" }` :
                ``;
        },
        mobile () {
            const { mobileCountry, mobileArea, mobileNumber } = this.client;
            return mobileCountry || mobileArea || mobileNumber ?
                `${ mobileCountry ? `+${ mobileCountry }` : "" } ${ mobileArea ?? "" } ${ mobileNumber ?? "" }` :
                ``;
        },
        pointScheme () {
            return this.client?.pointScheme ?? null;
        },
        pointBalance () {
            return this.client?.pointBalance ?? 0;
        },
        badges () {
            return this.client?.badges ?? [];
        },
    },
    methods: {
        async deleteClient () {
            await this.$store.commit("data/deleteClient", this.client.id);
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    z-index: 1;
}

.actions {
    bottom: 0;
}
</style>
