<template>
    <b-modal size="xl"
             hide-header
             hide-footer
             content-class="shadow border-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="position-sticky title d-flex justify-content-between align-items-center mb-4">
            <h1 class="font-weight-bolder mb-0">
                Send email 📧
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>

        <div>
            <b-form-group>
                <template v-slot:label>
                    📬 Recipient
                </template>
                <b-form-input disabled
                              v-bind:value="email">

                </b-form-input>
            </b-form-group>
        </div>

        <div>
            <label>
                📃 Content Designer
            </label>
            <div class="card shadow-sm overflow-hidden">
                <email-editor class="email-editor"
                              ref="emailEditor"
                              v-on:load="editorLoaded">
                </email-editor>
            </div>
        </div>

        <div class="position-sticky actions text-center pt-3 pb-1">
            <button class="btn btn-primary px-4"
                    type="submit">
                🕊️ Send
            </button>
        </div>
    </b-modal>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import {
    BFormGroup,
    BFormInput,
    BModal,
} from "bootstrap-vue";

export default {
    name: "ModalSendEmail",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
        EmailEditor,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        "client": {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        email () {
            return this.client?.email ?? "";
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        editorLoaded () {
            // Pass the template JSON here
            // this.$refs.emailEditor.editor.loadDesign({});
        },
        saveDesign () {
            this.$refs.emailEditor.editor.saveDesign(
                (design) => {
                    console.log('saveDesign', design);
                }
            )
        },
        exportHtml () {
            this.$refs.emailEditor.editor.exportHtml(
                (data) => {
                    console.log('exportHtml', data);
                }
            )
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}

.actions {
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 99;
}
</style>

<style lang="scss">
.email-editor {
    height: 80vh;

    iframe {
        width: 100% !important;
        min-width: auto !important;
    }
}
</style>
