<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow border-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title d-flex justify-content-between align-items-center mb-4">
            <h1 class="font-weight-bolder mb-0">
                Adjust points
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>

        <form v-on:submit.prevent="save">
            <b-form-group>
                <template v-slot:label>
                    Point Scheme
                </template>
                <b-form-input disabled
                              v-bind:value="client.pointScheme.name">
                </b-form-input>
            </b-form-group>

            <b-form-group>
                <template v-slot:label>
                    Balance
                </template>
                <b-form-spinbutton min="0" step="1"
                                   v-model="pointBalance">
                </b-form-spinbutton>
            </b-form-group>

            <div class="text-right">
                <button class="btn btn-sm btn-primary px-3"
                        type="submit">
                    Confirm
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import {
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BModal,
} from "bootstrap-vue";

export default {
    name: "ModalAdjustPoints",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
        BFormSpinbutton,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
        client: {
            type: Object,
        },
    },
    data () {
        return {
            pointBalance: 0,
        };
    },
    computed: {

    },
    methods: {
        hideModal() {
            this.$emit("input", false);
        },
        async save () {
            await this.$store.commit(
                "data/editClient",
                {
                    ...this.client,
                    "pointBalance": this.pointBalance,
                }
            );
            this.hideModal();
        },
    },
    mounted () {
        if (this.client) {
            this.pointBalance = this.client?.pointBalance ?? 0;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
